.patient-tracking-popup {
  width: 700px !important;


  .modal-content {
    padding: 0;

    .modal-body {
      padding: 0;

      .summary-wrapper {
        height: 126px;
        border-radius: 5px 5px 45px 45px;
        background-color: #00b0ec;
        padding: 23px 50px;

        .user-profile__avatar-section--img {
          width: 80px !important;
          height: 80px !important;
        }

        .summary-contant {
          color: white;
          font-family: "Source Sans Pro";

          .summary-contant-inner {
            line-height: 10px;
          }

          .supporter-summary {
            line-height: 16px;
          }

          .petname {
            font-size: 30px;
            font-weight: 700;
            padding-right: 5px;
            line-height: 40px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            display: inline-block;
            text-transform: capitalize;
          }

          .pet-parent {
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            display: inline-block;
            text-transform: capitalize;
            vertical-align: super;
          }

          .gender-icon-male {
            background: url(../shared/img/male.png);
            display: inline-block;
            height: 25px;
            width: 25px;
            margin: 0px 4px -4px 4px;
            background-repeat: no-repeat;
          }

          .gender-icon-female {
            background: url(../shared/img/male.png);
            display: inline-block;
            height: 25px;
            width: 25px;
            margin: 5px 5px -4px 5px;
            background-repeat: no-repeat;
          }

          .text {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }
      }

      .message-container{
        width: 660px;
        height: 520px;
        padding: 20px 5px 20px 20px;
        border-radius: 5px;
        background-color: #eef0f7;
        margin: 10px 20px;
        max-height: 54vh;

        .blocks-wrapper{
          color: #000000;
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-weight: 400;
          margin-right: 15px;

          .disable-custom-wrapper-drag {
            border:1px solid #bfc0c6 !important
          }

          .disable-custom-wrapper{
            color: #a7a8a7;
            border-radius: 5px;
            border: 1px solid #e0e2e0;
            background-color: #f6f6f6;
            margin-bottom: 5px;
            padding: 11px 20px;
            white-space: pre-line;

            .heading{
              display: inline-block;
            }
            .date{
              float: right;
              display: inline-block;
              padding-right: 20px;
            }

            .time{
              float: right;
              display: inline-block;
              padding-right: 20px;
            }

            .collapse-btn{
              display: inline-block;
              float: right;
              background: url(../shared/img/collaps-down.png);
              background-repeat: no-repeat;
              height: 25px;
              width: 25px;
              cursor: pointer;

              &.show{
                background: url(../shared/img/collaps-up.png);
                background-repeat: no-repeat;
              }
            }
            .collapse{
              &.show{

              }
            }
          }

          .disable-phase-wrapper{
            color: #a7a8a7;
            border-radius: 5px;
            border: 1px solid #e0e2e0;
            background-color: #f6f6f6;
            margin-bottom: 5px;
            padding: 11px 20px;
            word-break: break-word;

            .heading{
              display: initial;
              white-space: pre-line;
            }
            .dateTime-wrapper{
              display: inline-block;
              float: right;
              padding-left: 10px;

              .date{
                float: right;
                display: inline-block;
                padding-right: 20px;
              }

              .time{
                float: right;
                display: inline-block;
              }
            }

          }

          .drag-message {
            background: #E6F2F8 !important;
            border: 1px solid #37b0ec !important;
          }
          .active-wrapper{
            border-radius: 5px;
            border: 1px solid #eff0ef;
            background-color: #ffffff;
            margin-bottom: 5px;
            padding: 6px 10px 6px 20px;

            .collapse-btn{
              display: inline-block;
              float: right;
              background: url(../shared/img/collaps-down.png);
              background-repeat: no-repeat;
              height: 25px;
              width: 25px;
              margin: 6px 0;
              cursor: pointer;

              &.show{
                background: url(../shared/img/collaps-up.png);
                background-repeat: no-repeat;
              }
            }
            .collapse{

              &.show{

              }
            }

            textarea{
              width: 100%;
              position: relative;
              height: 99px;
              border-radius: 5px;
              border: 1px solid #eff0ef;
              background-color: #ffffff;
              padding: 10px;
              resize: none;

              &.edit{
                height: 86px;
                width: 94%;
              }
            }
          }

          .custom-button-wrapper{
            border-radius: 5px;
            border: 1px solid #eff0ef;
            background-color: #ffffff;
            margin-bottom: 5px;
            padding: 11px 20px;

            &.hidden{
              display: none;
            }
          }

          .custom-text-wrapper{
            border-radius: 5px;
            border: 1px solid #eff0ef;
            background-color: #ffffff;
            margin-bottom: 5px;
            padding: 6px 5px 6px 20px;

            &.hidden{
              display: none;
            }

            .text-area{
              width: 94%;
              height: 99px;
              border-radius: 5px;
              border: 1px solid #eff0ef;
              background-color: #ffffff;
              padding: 10px;
              resize: none;

              &::-webkit-input-placeholder {
                color: #c5cacf;
                font-family: "Source Sans Pro";
                font-size: 14px;
                font-weight: 400;
              }
            }
            .word-count{
              color: #c5cacf;
              font-family: "Source Sans Pro";
              font-size: 14px;
              font-weight: 400;
              display: inline-block;
              position: absolute;
              position: absolute;
              top: 65px;
              right: 20px;
              font-style: italic;

              .apply-btn{
                background: url(../shared/img/apply-icon.png);
                background-repeat: no-repeat;
                height: 25px;
                width: 25px;
                display: inline-block;
                background-size: contain;
                margin: 0px 0px -7px 10px;
                cursor:pointer;
              }
            }
          }
        }
      }

      .action-button-wrapper{
        width: 100%;
        padding: 10px 20px 20px 20px;
        text-align: right;
      }
    }
  }
  .states-block {
    margin: 0px 0;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;

    &.text{
      width: 100%;
      max-width: 100%;
      position: relative;
    }

    label{
      margin-bottom: 0px;
    }

    .checkbox-input {
      float: left;

      &:checked {
        position: absolute;
        left: -9999px;
      }
      &:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      &:checked + .checkbox-label {
        position: relative;
        padding: 0 0 0 30px;
        cursor: pointer;
        display: inline-block;
        text-overflow: ellipsis;

        span{
          color: #00b0ec;
          text-transform: uppercase;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          width: 16px;
          height: 16px;
          border: 1px solid #20c83a;
          border-radius: 2px;
          background: #20c83a;
        }

        &:after {
          content: '';
          width: 10px;
          height: 10px;
          background: url(../shared/img/filter-states-tick.png),;
          position: absolute;
          top: 4px;
          left: 3px;
          border-radius: 2px;
          transition: all 0.2s ease;
          opacity: 1;
          transform: scale(1);
        }
        &.custom-text{
          width: 30px;
          padding: 0px;
          vertical-align: top;
        }
        &.custom-massage{
          &:before {
            border: 1px solid #00b0ec;
            background: #ffffff;
          }
        }
      }
      &:not(:checked) + .checkbox-label {
        position: relative;
        padding: 0 0 0 30px;
        cursor: pointer;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: pre-line;

        span{
          color: #00b0ec;
          text-transform: uppercase;
        }

        &.custom-massage{
          &:before {
            border: 1px solid #00b0ec;
          }
        }

        &.custom-text{
          width: 30px;
          padding: 0px;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          width: 16px;
          height: 16px;
          border-radius:2px;
          background: #edf3f6;
        }

        &:after {
          content: '';
          width: 10px;
          height: 10px;
          background: #ffffff;
          position: absolute;
          top: 4px;
          left: 3px;
          border-radius: 2px;
          transition: all 0.2s ease;
          opacity: 0;
          transform: scale(0);
        }
      }
    }

    .checkbox-label {
      color: #000000;
      font-size: 14px ;
      font-family: "Source Sans Pro";
      font-weight: 400 ;

      &.custom-massage{
        color: #00b0ec;
      }
    }

  }
  .editable-div{
    width: 94%;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #eff0ef;
    padding: 5px;
    word-break: break-word;

    input{
      border: 1px solid #9c9c9c !important;
      padding: 5px;
      border-radius: 4px;
      height: auto !important;
      min-width: 191px;
      margin-top: 3px;

      &::-webkit-input-placeholder { /* Edge */
        color: #00b0ec;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #00b0ec;
      }

      &::placeholder {
        color: #00b0ec;
      }
    }

    span{
      display: inline-block;
      width: auto;
      height: auto !important;
    }
    select{
      display: inline-block;
      width: auto;
      border: 1px solid #9c9c9c !important;
      font-size: 12px;
      border-radius: 4px !important;
      padding: 5px;
      height: auto !important;

      option {
        color: #00b0ec;
      }
    }

    .react-dropdown-select {

      .react-dropdown-select-content{

        .react-dropdown-select-option{
          background: #00b0ec;

          .react-dropdown-select-option-label{
            font-size: 12px;
            font-weight: 600;
            padding: 1px;
            margin-right: 5px;
          }
          .react-dropdown-select-option-remove{
            margin-right:5px;
          }

        }
        input{
          border: none !important;
        }
      }

      .react-dropdown-select-dropdown{

        .react-dropdown-select-item{

        }

        .react-dropdown-select-item-selected{
          background: #00b0ec;
        }
      }
    }
  }

  .error-text{
    font-size: 14px;
    font-weight: $font-weight-regular;
    line-height: 20px;

    &.error{
      color: #ff0314;
      text-align: left;
    }
    &.success{
      color: #00ff18;
    }
  }
}

.btn-popup-clear {
  margin-right: 10px;
}
