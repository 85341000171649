.unsubscribe {

  .account__wrapper {
    padding: 0;

    .account__card {
      padding: 0;
      height: auto;

      .account__card--inner {
        text-align: center;

        .account__head {
          height: 100px;
          box-shadow: inset 0 -1px 0 rgba(235, 236, 237, 0.77);
          background-color: #ffffff;

          .account__title {
            padding: 34px;

            .account__logo {
              img {
                width: 147px;

              }
            }
          }
        }

        .body-wrapper {
          padding: 30px 90px 60px 90px;

          .subheading {
            color: #000000;
            font-size: 24px;
            font-weight: 700;
            padding-bottom: 35px;
            font-family: Roboto;
          }

          .p-first {
            color: #000000;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            padding-bottom: 29px;
          }

          .p-second {
            color: #000000;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
          }

          a {
            border-radius: 5px;
            background-color: #00b0ec;
            display: inline-block;
            margin: 10px 0 40px;
            width: auto;
          }

          .your {
            color: #000000;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
          }
        }

      }
    }
  }


}