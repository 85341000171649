// Font Weights
$font-weight-extra-light : 200;
$font-weight-light       : 300;
$font-weight-regular     : 400;
$font-weight-medium      : 500;
$font-weight-semi-bold   : 600;
$font-weight-bold        : 700;
$font-weight-extra-bold  : 800;

//colors

$white: #ffffff;
$black: #000000;