#loyal-logo {
    position: absolute;
    left: 100px;
    top: 14px;

    .logo-image {
        width: 130px;
    }
    
    .loyal {
        width: 79px;
        height: 23px;
        color: #0631b4;
        font-family: "Colus - Regular";
        font-size: 20px;
        font-weight: 400;
        
        .pet {
            color: #20c83a;
        }
    };

}