.manage-admin{

  .btn-new-admin {
    font-weight: 700;
  }
  
  .lnr-paw{
    font-size: 25px;
    color: gray;
    cursor: pointer;
  }
  
}
.modal-body{
  .form {
    display: inherit !important;
  }
}