.matrix-dashboard {

  .btn-mixpanel {
    float: right;
    margin-right: 20px;
    color: #00B0EC;
    background-color: #FFFFFF;
  } 
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    .card-body {
      padding: 20px 30px;
      height: 100%;
      border-radius: 5px;

      .heading {
        color: #000000;
        font-size: 15px;
        font-weight: 600;
      }

      
      .facility-wrapper {
        text-align: right;
        .facility-name {
          color: #0631B4;
          font-size: 14px;
          padding-right: 0;
        }
        
        .custom-select-box-wrapper {
          width: 200px;
          height: 40px;
          background-color: #ffffff;
          display: inline-block;

          .search-state {
            border: 1px solid #e0e2e0;
            background-image: url(../shared/img/select-down-arrow.png);
            cursor: pointer;

            &::-webkit-input-placeholder {
              color: #000000;
              font-size: 14px;
              font-weight: 400;
            }

            &::-moz-placeholder {
              color: #000000;
              font-size: 14px;
              font-weight: 400;
            }

            /* Firefox 19+ */
            &:-moz-placeholder {
              color: #000000;
              font-size: 14px;
              font-weight: 400;
            }

            /* Firefox 18- */
            &:-ms-input-placeholder {
              color: #000000;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .states-result {
            border: 1px solid #e0e2e0;
            top: 41px;
            box-shadow: none;

            .blocks-wrapper {
              padding: 5px 0 0 0;

              .states-block {
                text-align: left;
                margin: 5px 0;

                .checkbox-input:checked + .checkbox-label {
                  max-width: 210px;

                  &:before {
                    width: 16px;
                    height: 16px;
                  }

                  &:after {
                    top: 3px;
                    left: 3px;
                  }
                }

                .checkbox-input:not(:checked) + .checkbox-label {
                  max-width: 210px;

                  &:before {
                    border: none;
                    border-radius: 0px;
                    box-shadow: inset 0 -1px 0 rgba(135, 141, 144, 0.1);
                    background-color: #edf3f6;
                    width: 16px;
                    height: 16px;
                  }
                }

              }
            }

            .states-result-footer {
              position: relative;
              box-shadow: none;
              border: 0 none;
              background-color: #fff;
              padding: 10px;

              .btn-apply {
                width: 100%;
                max-width: 160px;
                margin: 0 auto;
                float: none;
                display: block;
                height: 40px;
                border-radius: 5px;
                border: 1px solid #00b0ec;
                background-color: #ffffff;
                color: #00b0ec;
                font-size: 14px;
                font-weight: 700;
                padding: 5px 20px;

                &.disabled {
                  cursor: none;
                }
              }
            }
          }
        }

        Label {
          padding-right: 21px;
          color: #50514f;
          font-size: 12px;
          font-weight: 600;
          margin: 0;
          vertical-align: top;
          padding-top: 12px;
        }


      }

      .total-average {
        padding: 20px;
        border-radius: 5px;
        border: 1px solid #e0e2e0;
        background-color: #eef0f7;
        margin-top: 20px;

        Label {
          color: #50514f;
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          padding-bottom: 10px;
        }

        .no-padding {
          padding: 0;
        }

        .total-user-3 {
          padding-right: 10px;

          .total-wrapper {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #e0e2e0;
            background-color: #ffffff;
            padding: 20px;
            height: 169px;

            .row {
              padding: 0px 0px 0px 20px;
            }

            .bullets {
              width: 80px;
              padding: 0;
              display: inline-block;
              padding-top: 16px;

              span {
                display: block;
                color: #2b2b2b;
                font-size: 14px;
                font-weight: 400;
                line-height: 30px;

                .client {
                  background-color: #fbc756;
                  width: 12px;
                  height: 12px;
                  display: inline-block;
                  border-radius: 6px;
                }

                .pet {
                  background-color: #11a5a5;
                  width: 12px;
                  height: 12px;
                  display: inline-block;
                  border-radius: 6px;
                }

                .provider {
                  background-color: #1983c3;
                  width: 12px;
                  height: 12px;
                  display: inline-block;
                  border-radius: 6px;
                }
              }
            }

            .chart-container {
              width: 50%;
              display: inline-block;
              float: right;
              max-width: 120px;
              padding-top: 16px;

              svg {
                path {
                  title {
                    background-color: #00B7FF;
                    color: #00ff18;
                  }
                }
              }

            }
          }
        }


        .provider-by-designation-7 {
          padding: 0;

          .provider-by-designation-wrapper {
            border-radius: 5px;
            border: 1px solid #e0e2e0;
            background-color: #ffffff;
            padding: 20px;
            width: 100%;
            height: 169px;

            .row {
              padding-top: 30px;

              .single-designation {
                .label {
                  color: #868886;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                  width: 100px;
                  display: inline-block;
                }

                .value {
                  color: #000000;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                }
              }
            }
          }
        }

        .average-2 {
          padding-left: 10px;

          .average-wrapper {
            width: 100%;
            height: 169px;

            .pet {
              border-radius: 5px;
              border: 1px solid #e0e2e0;
              background-color: #0631b4;

              .text {
                color: #ffffff;
                font-size: 13px;
                font-weight: 600;
                text-align: center;
                display: block;
                padding-top: 8px;
                line-height: 16px;
              }

              .value {
                color: #fdfdff;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                display: block;
              }
            }

            .supporter {
              height: 80px;
              border-radius: 5px;
              border: 1px solid #e0e2e0;
              background-color: #15153e;
              margin-top: 9px;

              .text {
                color: #ffffff;
                font-size: 13px;
                font-weight: 600;
                text-align: center;
                display: block;
                padding-top: 8px;
                line-height: 16px;
              }

              .value {
                color: #fdfdff;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                display: block;
              }
            }
          }
        }

      }

      .phase-history {
        .total-average-wrapper {
          .bullet-wrapper {
            width: 100% !important;

            .bullets {
              width: 25% !important;
              float: left !important;
              span {
                span {
                  width: 16px !important;
                  height: 16px !important;
                  display: inline-block !important;
                  border-radius: 8px !important;
                  border: 2px solid #ffffff !important;
                  vertical-align: sub !important;
                }
                .expected {
                  background-color: #00205B;
                }
                .diagnostic {
                  background-color: #FFD900;
                }
                .procedure {
                  background-color: #0bbd0b;
                }
                .surgery {
                  background-color: #006935;
                }
                .hospitalized {
                  background-color: #774AA4;
                }
                .discharge {
                  background-color: #FF6A00;
                }
                .checkin {
                  background-color: #00AEFF;
                }
                .preparation {
                  background-color: #2dde98;
                }
                .dental {
                  background-color: #129d58;
                }
                .recovery {
                  background-color: #FF0092;
                }
                .boarding {
                  background-color: #862633;
                }
                .completed {
                  background-color: #1CC7D0;
                }
              }
            }
          }
        }
        
      }

      .new-user {
        margin-top: 10px;
        padding: 10px 20px 20px 20px;

        .header-container {
          height: 50px;

          h3 {
            color: #000000;
            font-family: "Source Sans Pro";
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            display: inline-block;
            padding-top: 8px;
          }

          .form-group {
            display: inline-block;
            width: 190px;
            float: right;

            select {
              border: 1px solid #e0e2e0;
              background-color: #ffffff;
              border-radius: 0px;
              color: #000000;
              font-family: "Source Sans Pro";
              font-size: 14px;
              font-weight: 400;
              height: 40px;
              transition: none;


              option {
                color: #000000;
                font-family: "Source Sans Pro";
                font-size: 14px;
                font-weight: 400;
                margin: 20px;
                padding: 20px;
                line-height: 25px;

              }
            }
          }
        }

        .sub-header-container {
          position: relative;
          height: 50px;
          .wrapper{
            position: absolute;
            right: 0;
            .facility-wrapper{
              .custom-select-box-wrapper{
                width: 160px;
              }
            }
          }
        }

        .chart {
          width: 100%;
          height: 246px;
          border-radius: 5px;
          border: 1px solid #e0e2e0;
          background-color: #ffffff;
          color: #868886;
          font-family: "Source Sans Pro";
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;

          .recharts-wrapper {

            .recharts-surface {

              .recharts-cartesian-grid{

                .recharts-cartesian-grid-horizontal{

                  line{
                    stroke: #eef0f7;
                  }
                }
              }

              .xAxis {

                .recharts-cartesian-axis-line{
                  stroke: #eef0f7;
                }

                .recharts-cartesian-axis-ticks {

                  .recharts-layer {

                    .recharts-cartesian-axis-tick-line {

                      .recharts-cartesian-axis-tick-value {

                      }
                    }
                  }
                }
              }
              .yAxis {

                .recharts-cartesian-axis-line{
                  stroke: #eef0f7;
                }

                .recharts-cartesian-axis-ticks {

                  .recharts-layer {

                    .recharts-cartesian-axis-tick-line {
                      display: none;

                      .recharts-cartesian-axis-tick-value {

                      }
                    }
                  }
                }
              }
            }
          }
        }

        .total-average-wrapper {
          padding: 20px 15px 0px 0px;

          .label {
            padding-right: 0;
            width: 15%;
            display: inline-block;

            &.procedure-messages{
              margin-left: 10px;
            }

            span {
              color: #000000;
              font-size: 15px;
              font-weight: 600;
              padding: 10px 0px;
              display: block;
            }
          }

          .client {
            width: 20%;
            display: inline-block;

            span {
              display: block;
              height: 40px;
              box-shadow: inset -5px 0 0 #fbc756;
              background-color: #ffffff;
              margin-bottom: 5px;
              color: #322f31;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              padding: 7px;
              border: 1px solid #eef0f7;
            }
          }

          .pet {
            width: 20%;
            display: inline-block;

            span {
              display: block;
              height: 40px;
              box-shadow: inset -5px 0 0 #11a5a5;
              background-color: #ffffff;
              margin-bottom: 5px;
              color: #322f31;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              padding: 7px;
              border: 1px solid #eef0f7;
            }
          }

          .provider {
            width: 20%;
            display: inline-block;

            span {
              display: block;
              height: 40px;
              box-shadow: inset -5px 0 0 #1983c3;
              background-color: #ffffff;
              margin-bottom: 5px;
              color: #322f31;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              padding: 7px;
              border: 1px solid #eef0f7;
            }

            &.procedure-messages{
              span{
                box-shadow: inset -5px 0 0 #0631b4;
              }
            }
          }

          .bullet-wrapper {
            width: 25%;
            vertical-align: bottom;
            display: inline-block;

            .bullets {
              width: 80px;
              padding: 0;
              display: inline-block;
              float: right;


              span {
                display: block;
                color: #2b2b2b;
                font-size: 14px;
                font-weight: 400;
                line-height: 30px;

                .client {
                  background-color: #fbc756;
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  border-radius: 8px;
                  border: 2px solid #ffffff;
                  vertical-align: sub;
                }

                .pet {
                  background-color: #11a5a5;
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  border-radius: 8px;
                  border: 2px solid #ffffff;
                  vertical-align: sub;
                }

                .provider {
                  background-color: #1983c3;
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  border-radius: 8px;
                  border: 2px solid #ffffff;
                  vertical-align: sub;
                }
              }
            }
          }

          &.msg-sent{
            padding-right: 0;
            .total-wrapper, .avg-wrapper{
              float: left;
            }
            .total-wrapper{
              width: 50%;
            }

            .avg-wrapper{
              width: 50%;
            }
            .label{
              float: left;
              width: 40%;
            }
            .provider{
              width: 40%;
            }
          }

        }

      }

      .procedure-details{
        border-radius: 5px 5px 0 0;

        .total-average-wrapper {
          padding: 20px 15px 0px 0px;
          .label {
            padding-right: 0;
            width: 15%;
            display: inline-block;

            span {
              color: #000000;
              font-size: 15px;
              font-weight: 600;
              padding: 10px 0px;
              display: block;
            }
          }

          .initiated {
            width: 20%;
            display: inline-block;

            span {
              display: block;
              height: 40px;
              box-shadow: inset -5px 0 0 #56fba9;
              background-color: #ffffff;
              margin-bottom: 5px;
              color: #322f31;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              padding: 7px;
              border: 1px solid #eef0f7;
            }
          }

          .completed {
            width: 20%;
            display: inline-block;

            span {
              display: block;
              height: 40px;
              box-shadow: inset -5px 0 0 #c724d1;
              background-color: #ffffff;
              margin-bottom: 5px;
              color: #322f31;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              padding: 7px;
              border: 1px solid #eef0f7;
            }
          }

          .closed {
            width: 20%;
            display: inline-block;

            span {
              display: block;
              height: 40px;
              box-shadow: inset -5px 0 0 #ed3434;
              background-color: #ffffff;
              margin-bottom: 5px;
              color: #322f31;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              padding: 7px;
              border: 1px solid #eef0f7;
            }
          }

          .bullet-wrapper {
            width: 25%;
            vertical-align: bottom;
            display: inline-block;

            .bullets {
              width: 95px;
              padding: 0;
              display: inline-block;
              float: right;


              span {
                display: block;
                color: #2b2b2b;
                font-size: 14px;
                font-weight: 400;
                line-height: 30px;

                .initiated {
                  background-color: #56fba9;;
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  border-radius: 8px;
                  border: 2px solid #ffffff;
                  vertical-align: sub;
                }

                .completed {
                  background-color: #c724d1;
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  border-radius: 8px;
                  border: 2px solid #ffffff;
                  vertical-align: sub;
                }

                .closed {
                  background-color: #ed3434;
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  border-radius: 8px;
                  border: 2px solid #ffffff;
                  vertical-align: sub;
                }
              }
            }
          }
        }
      }

      .average-closed-wrapper {
        text-align: center;
        height: 45px;
        border-radius: 0 0 5px 5px;
        background-color: #d9dce6;
        color: #2b2b2b;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        padding-top: 7px;

        span{
          color: #000000;
          font-size: 24px;
          font-weight: 600;
          letter-spacing: -0.18px;
          line-height: 28px;
          display: inline-block;
          vertical-align: sub;
          padding-left: 10px;
        }
      }

    }

    .loyal-last-process-date {
      margin-top: 0;
      font-size: 11px;
      color: #868886;
    }
  }
}

