.manage-breed {

  .btn-new-breed {
    margin-top: 30px;
    font-weight: 600;
  }

  .btn-new-breed:hover {
    color: #1983c3 !important
  }

  .btn-new-breed:focus {
    color: #1983c3 !important
  }

  
}

.modal-breed {
  .div-button {
    margin-left: 70% !important;
  }
}