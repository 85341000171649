.rc-time-picker-panel-input-wrap {
  height: 40px !important;
  padding: 0.575rem 0.75rem !important;
}

.rc-time-picker-panel-input {
  text-transform: uppercase;
}

.rc-time-picker-panel-inner {
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 3px 0 0 !important;

}
