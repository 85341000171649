.user-profile {

    .btn-primary {
        height: 40px;
    }
    
    .col-1 {
        min-width: 150px;
    }

    &__avatar-section {

        &--img {
            float: left;
            width: 161px !important ;
            height: 161px !important;
        }
    }

    &__avatar-details-section {
        margin-left: 40px;
        margin-top: 17px;
        &--name {
            height: 39px;
            color: #322f31 !important ;
            font-size: 30px;
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &--post {
            width: 430px;
            height: 23px;
            color: #818383 !important;
            font-size: 16px;
            font-weight: 400;
        }

        &--hospital {
            width: 430px;
            height: 23px;
            color: #000000 !important;
            font-size: 16px;
            font-weight: 600;
        }

        &--upload-btn {
            border-radius: 5px;
            background-color: white !important;
            color: #00b0ec;
            border: 1px solid #00b0ec !important;
            font-size: 14px;
            font-weight: 700;
            margin-top: 20px;
            padding-right: 13px;
            .lnr-plus-circle {
                font-size: 18px;
                position: absolute;
                left: 15px;
            }
            .text {
                margin-left: 15px;
            }
        }

        .btn-primary::before {
            background-color: #ffffff;
        }
    
        .btn-primary:hover {
            color: #00b0ec !important;
        }
    }

// profile form

.profile-details {
    clear: both;
    margin-top: 90px;
    .form__form-group-field {
        margin: inherit;
    }
    .view-profile-text {
        color: #00b0ec;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-weight: 700;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        .lnr-arrow-right {
            font-size: 15px;
            font-weight: 800;
            position: absolute;
            top: 4px;
            left: 78px;
        }
    }

   
    .form {
        margin-top: 20px;
        display: inherit;
        &__form-group-field {
            max-width: 320px;
            input {
                height: 40px;
            }
        }
        
        &__form-group-label {
            height: 15px;
            color: #50514f;
            font-size: 12px;
            font-weight: 600;
        }
        
        &__form-group {
            width: auto;
        }
        .heading-text--contact {
            position: absolute;
            top: -51px;
        }

        .contact-row {
            max-width: 350px;
        }
    }
  
}

.profile-details-password-reset {
    margin-top: 70px;
    .form {
        margin-top: 20px;
        display: inherit;
        &__form-group-field {
            max-width: 320px;

            input {
                height: 40px;
            }
        }
        
        &__form-group-label {
            height: 15px;
            color: #50514f;
            font-size: 12px;
            font-weight: 600;

            img {
                width: 10px;
                height: 10px;
                margin-left: 2px;
                margin-bottom: 2px;
            }

            
        }
    }
    .form__form-group-field {
        margin: inherit;
    }
}

}   
    .tooltip-inner {
        background-color: #edf3f6;
        color: #1983c3;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        min-width: 430px;
        padding: 20px;
    }