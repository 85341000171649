.model-form {
  &.modal-dialog {
    width: 70%;
    max-width: 900px;

    .card-body {
      padding: 0;
    }

    .modal-body {
      padding: 40px;

      .form-group {
        width: 100%;
      }

      input,
      .form-control {
        height: 40px;
        border: 1px solid #f4f5f6;
        background-color: #ffffff;
        outline: none;
        box-shadow: none;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  &.modal-dialog-facility {
    width: 90%;
    max-width: 1070px;
  }

  &.modal-dialog-client {
    width: 500px;
    border-radius: 5px 5px 0 0;
    background-color: #00b0ec;
    max-width: 1070px;

    .modal-body {
      padding: 30px 20px;

      .text-right {
        margin-top: 20px;

        .btn {
          margin-right: 5px;
          padding: 8px 40px;
        }
      }

      .custom-select-box-wrapper {
        border-radius: 5px;
        background-color: #eef0f7;
        padding: 15px 5px 20px 20px;

        .states-result {
          position: initial;
          background-color: #eef0f7;
          border: none;
          text-shadow: none;
          border-radius: 0px;
          box-shadow: none;

          .blocks-wrapper {
            background-color: #eef0f7;
            padding: 0;

            .no-facility-text {
              text-align: center;
              margin-top: auto;
              margin-bottom: auto;
              padding-top: 70px;
              padding-right: 10px;
              font-size: 16px;
              font-weight: 400;
              opacity: 0.5;
            }

            .states-block {
              border-radius: 5px;
              border: 1px solid #eff0ef;
              background-color: #ffffff;
              margin: 5px 0;
              width: 96%;

              .checkbox-label {
                text-transform: none;
                color: #000000;
                font-family: "Source Sans Pro";
                font-size: 14px;
                font-weight: 400;
                margin: 10px 0px 5px 0px;
                padding: 0 20px 0 38px;
                max-width: 400px;
              }

              .checkbox-input:checked + .checkbox-label:before {
                width: 15px;
                height: 15px;
                margin-top: 3px;
              }

              .checkbox-input:checked + .checkbox-label:after {
                top: 2px;
                left: 2px;
                margin-top: 3px;
              }

              .checkbox-input:not(:checked) + .checkbox-label:before {
                width: 15px;
                height: 15px;
                margin-top: 3px;
              }

              .checkbox-input:not(:checked) + .checkbox-label:after {
                top: 2px;
                left: 3px;
                margin-top: 3px;
              }

              .checkbox-label {
                color: #000000;
                font-family: "Source Sans Pro";
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  max-width: max-content;

  .header-form {
    background-color: #00b0ec;
    color: #ffffff;
  }

  .modal-header {
    .modal-title {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 23px;
    }
  }

  .modal-content {
    padding: 0px;
    text-align: left;
    border-radius: 5px;

    .form-control {
      border-radius: 0px;
    }

    .form-select {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      height: 32px;
      transition: border 0.3s;
      background: transparent;
    }

    .form-check-input {
      height: auto;
      position: relative;
    }

    .btn-generate {
      border-radius: 0px;
      padding: 10px 25px;
      margin-top: 20px;
      margin-right: 15px;
      transition: all 0.4s;
      font-size: 10px;
      position: relative;
      overflow: hidden;
      z-index: 0;
    }

    .card {
      padding-bottom: 0px;
    }
  }

  .btn {
    height: 40px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .clear {
    background-color: #15153e;
  }

  .tab-content {
    padding: 10px;
    border: 1px solid #e0e2e0;
    background-color: #eef0f7;
  }

  .loyal-image-upload-box {
    border: 1px dashed #c5c5db;
    padding: 20px;
    margin-bottom: 10px;

    .col-md-12 {
      margin: 0px auto;
      text-align: center;
    }

    .loyal-btn-outline-primary,
    .loyal-btn-outline-primary:active {
      height: 40px;
      border-radius: 5px;
      border: 1px solid #00b0ec;
      background-color: #ffffff;
      color: #00b0ec;
      font-weight: 700;
      padding: 10px 15px;

      .mdi-icon {
        fill: #00b0ec;
        width: 19px;
        height: 19px;
        margin-top: -4px;
      }
    }

    .lnr {
      margin-right: 5px;
    }

    .btn-green {
      border: 1px solid #72bb38;
      color: #72bb38;
    }

    .loyal-uploaded-image {
      border-radius: 50%;
      width: 76px;
      height: 76px;
      border: 1px solid #eef0f7;
      margin-bottom: 20px;
      object-fit: none;
    }

    .loyal-uploaded-image-logo {
      width: 138px;
      height: 78px;
      margin-bottom: 20px;
      border-radius: 6px;
    }
  }

  .loyal-facility-tab-container {
    .nav-link {
      font-size: 14px;
      font-weight: 400;
      border-bottom: none !important;
      transition: unset;
      -webkit-transition: unset;
    }

    .nav-link.active {
      border: 1px solid #e0e2e0;
      background-color: #eef0f7;
      color: #000000;
      font-weight: 600;
    }

    .nav-tabs {
      margin-left: 25px;
      border-bottom: none;
      margin-bottom: -1px;
    }

    .nav-link {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      cursor: pointer;
    }

    .tab-content {
      border-radius: 6px;
      min-height: 325px;
      padding: 30px;
      margin-bottom: 10px;
    }

    .form__form-group-label {
      font-size: 13px;
      font-weight: 600;
    }

    .loyal-btn-outline-primary,
    .loyal-btn-outline-primary:active {
      height: 40px;
      border-radius: 5px;
      border: 1px solid #00b0ec;
      background-color: #ffffff;
      color: #00b0ec;
      font-weight: 700;
      padding: 10px 15px;
    }
    .form{
      textarea{
        min-height: 145px;
      }
    }
  }

  .nav-tabs .nav-link {
    border-color: #e9ecef #e9ecef #dee2e6;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border: 1px solid #e0e2e0;
    background-color: #eef0f7;
  }
}

.alert-modal {
  img {
    width: 60px;
    height: 60px;
    color: #72bb38;
    margin-bottom: 20px;
  }

  &.crop-images {
    img {
      width: auto;
      height: auto;
      margin: 0;
    }
    .cropped-image {
      width: 150px;
      display: block;
      margin: 0 auto;
      border-radius: 100px;
    }
  }

  .modal__body {
    padding: 30px 30px 20px 30px;
    color: #2b2b2b;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .btn {
    min-width: 120px;
    height: 40px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .btn-yes {
    background-color: #00b0ec;
  }

  .btn-no {
    background-color: #15153e;
  }

  .modal-content {
    border-radius: 5px;
    background-color: #ffffff;
  }

  .modal__footer {
    margin-bottom: 30px;
    justify-content: center;
    padding-right: 0px;
  }
}

.ptb-success-popup {
  width: 430px;

  .modal__body {
    padding: 20px;

    .ptb-success-model-close-btn {
      background: url(../shared/img/ptb-success-model-close-btn.png);
      display: inline-block;
      height: 32px;
      width: 32px;
      background-repeat: no-repeat;
      float: right;
      cursor: pointer;
    }

    img {
      margin-top: 54px;
      margin-right: 32px;
      padding-left: 32px;
      width: 92px;
    }
    p {
      color: #2b2b2b;
      font-family: "Source Sans Pro";
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      width: 300px;
      display: inline-block;
      word-break: break-word;
    }

    .footer-text {
      color: #c5cacf;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-weight: 400;
      padding: 20px;

      .date {
        padding-right: 20px;
      }
    }
  }
}
.facility-area{
  min-height: 145px !important;
}
.add-new-pet-wrapper {
  .Select-control {
    border: 1px solid #f4f5f6;

    input {
      border: none !important;
    }
  }
}
