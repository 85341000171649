.patient-tracking {

  .card-body {
    padding: 0 30px 30px 30px;

    .logo {
      max-width: 128px;
      max-height: 128px;
      float: right;
      margin-top: 30px;
      margin-right: 35px;
    }

    .btn-search {
      background: #72BB38;
      color: #FFFFFF;
      margin-top: 32px;
    }

    .icon-maximize {
      .lnr-frame-expand {
        font-size: 30px;
        position: absolute;
        right: 15px;
        cursor: pointer;
        z-index: 100;

        background: url(../shared/img/full-screen.png);
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        top: 30px;

        &::before {
          content: none;
        }
      }
    }

    .search-wrap {
      padding-right: 0px;
    }

    .search-btn-wrap {
      padding-left: 5px;
    }

    .icon-minimize {
      .lnr-frame-contract {
        font-size: 30px;
        position: absolute;
        left: 10px;
        cursor: pointer;
        top: -9px
      }
    }

    .board {

      .card {
        padding-bottom: 0px;

        .turquoise {
          background: #1CC7D0 !important;
        }

        .name {
          border-left: 3px solid red;
          padding: 0px 1px 0px 6px;
          text-transform: capitalize;
        }

        .date-time {
          background: #F6F6F6;
          font-size: 10px;
          padding: 4px 0px 4px 12px;
          color: #868886;

          .first {
            display: block;
          }

          .second {
            display: block;
          }
        }
      }
    }

    // end of board
    .maximize {
      position: fixed;
      top: -40px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 110;
      margin-top: 50px;
    }

    .smooth-dnd-container {
      margin-top: 10px;

      section.react-trello-board {
        background: #EEF0F7 !important;
        width: 8.4%;
        margin: 4px -1px 4px 0px;
        padding: 6px;
        border-radius: 0px;
        border-left: 1px solid #dcdfe0;
        border-right: 1px solid #dcdfe0;

        .smooth-dnd-container {
          background-color: #EEF0F7;
          height: 98%; 
          
          .smooth-dnd-draggable-wrapper {
            background-color: #EEF0F7;
          }
        }

        header {
          text-align: left;
          padding: 0px;
          margin-left: -6px;
          margin-right: -6px;
          border-bottom: 2px solid #fff;
        }

        .iTzKeC {
          font-size: 14px;
          font-weight: 600;
          width: 100%;
          padding: 3px 4px 8px 8px;
        }

        .sc-EHOje {

          &.cqkFMI {
            margin-top: 0px;
            width: 100%;
            max-width: 100%;
            min-width: 100%;

            .smooth-dnd-container {

              .smooth-dnd-draggable-wrapper {

                .eoPYBZ {

                  .card {

                    .name {

                      .first {
                        color: #000000;
                        font-size: 8px;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding: 0;
                        height: 24px;
                        padding-right: 0px;
                        font-weight: 600;

                        span {
                          font-size: 16px;
                          font-weight: 600;
                        }
                      }

                      .second {
                        color: #868886;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 8px;
                        font-weight: 400;
                        line-height: 15px;
                        padding-bottom: 5px;
                        padding-right: 0px;

                        span {
                          font-size: 14px;
                          font-weight: 400;
                        }
                      }
                    }

                    .date-time {
                      font-size: 10px;
                      font-weight: 400;
                      text-transform: uppercase;
                      box-shadow: inset 0 1px 0 #edf3f6;
                      padding: 4px 0px 4px 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }

    div.react-trello-board {
      background-color: white !important;
      height: 100vh;

      &>div:first-child {
        width: 100%;
      }

      .react-trello-lane {
        background: #EEF0F7 !important;

        .smooth-dnd-container {
          border-top: 2px solid white;
          padding-top: 15px;
          margin-top: 0px;
        }
      }
    }

    article.react-trello-board {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: none;
      border-radius: 3px;

      .card {
        box-shadow: inset 0 -1px 0 #edf3f6;

        .name {
          padding: 0px 1px 0px 6px;
        }
      }
    }

    .react-trello-card {
      box-shadow: none !important;
      border-radius: 0px !important;
    }
  }
}

.ptb-board {
  .smooth-dnd-container {
    height: 98% !important;
    
  }
  .smooth-dnd-container:hover {
    background: #e4e6ec !important;
  }
}
