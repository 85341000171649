.parent-signup {
  background-image: url(../shared/img/parent-signup.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 132px;
  min-height: 100vh;

  @include tablet-portrait {
    padding: 50px 20px 0;
    background-position: 30% 50%;
    min-height: 100vh;
  }

  @include mobile {
    background-image: url(../shared/img/parent-signup-mobile.png);
    padding-top: 0;
    min-height: 100vh;
  }

  @include mobile-portrait {
    background-position-y: -100px;
  }
  @include mobile-landscape {
    padding-top: 20px;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.77px;
    color: $white;
    margin-top: 43px;

    @include mobile {
      padding: 10px 15px;
      margin-top: 0;
    }
  }
  hr {
    opacity: 0.4;
    background: $white;
    margin-top: 28.9px;

    @include mobile {
      display: none;
    }
  }
  .sub-title {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: $white;
    opacity: 0.8;
    margin-top: 16px;

    @include mobile {
      padding: 6px 15px 40px;
      margin-top: 0;
    }
  }
  .logo-wrapper {
    margin-top: -40px;
    img {
      width: 175px;
    }
    @include mobile {
      padding: 30px 15px;
      margin-top: 0;
    }
    @include tablet-portrait {
      margin-top: 0;
    }
  }
  .form-wrapper {
    margin: 0 -5px;
    padding: 32px 32px 30px;
    background-color: $white;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    .form-title {
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.68px;
      text-align: center;
      color: #030303;
      margin-bottom: 24px;
    }
    .form-group {
      display: block;
      width: 100%;
      label {
        font-size: 14px;
        margin-bottom: 6px;
      }
      .form-control {
        font-size: 16px;
        height: 44px;
        margin-bottom: 10px;
        background: transparent;
        border: 1px solid #e0e0e4;
        &.readonly {
          background-color: #eee !important;
        }

        &.error {
          border-color: #ff0000;
        }
        &.email {
          text-transform: lowercase;

          &::-webkit-input-placeholder {
            text-transform: capitalize;
          }
          &::-moz-placeholder {
            text-transform: capitalize;
          }
          &:-ms-input-placeholder {
            text-transform: capitalize;
          }
          &:-moz-placeholder {
            text-transform: capitalize;
          }
        }
      }
      .loyal-error-label {
        float: right;
        font-size: 12px;
        width: 100%;
      }
      .password {
        font-size: 16px;
        height: 44px;
        margin-bottom: 10px;
        background: transparent;
        border: 1px solid #e0e0e4;
        border-radius: 0.25rem;

        input {
          height: 44px;
          font-size: 16px;
        }
        button {
          height: 44px;
        }
      }
    }
    .token-error {
      text-align: center;
      color: red;
    }
  }
  .success-wrapper {
    margin: 44px auto;
    width: 570px;
    padding: 32px 0 40px;
    border-radius: 12px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
    background-color: rgba(255, 255, 255, 0.88);

    &.error {
      padding-top: 65px;
    }

    @include mobile {
      width: 100%;
    }

    .message {
      text-align: center;

      img {
        width: 60px;
        height: 60px;
      }
      p {
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.68px;
        text-align: center;
        color: #030303;
        margin: 12px 0 44px;

        @include mobile {
          margin-bottom: 47px;
        }
      }
    }
    .app-download {
      .btn-download {
        background-color: transparent;
        border: none;
        box-shadow: none;
        height: 48px;
        width: 145px;
        background-repeat: no-repeat;

        @include mobile {
          display: block;
          margin: 0 auto;
        }

        &.google-play {
          background-image: url(../shared/img/logo/google-play.svg);
          width: 161.4px;
          margin-right: 15.6px;
          @include mobile {
            background-image: url(../shared/img/logo/google-play-mobile.svg);
            width: 145px;
            margin: 10.9px auto 0;
          }
        }
        &.apple-store {
          background-image: url(../shared/img/logo/app-store.svg);
        }
      }
    }
  }
  .agreement {
    opacity: 0.5;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #030303;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .tos-pp {
    color: #00b0ec;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    a {
      padding: 0 10px;
    }
  }

  .footer-text {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #adadad;
    margin: 64px 0 15px;

    @include mobile {
      font-size: 12px;
      margin: 20px 0;
    }
  }
}

.signup-model {
  width: 95% !important;
  .modal-content {
    .pp-model-header {
      height: 60px;
      font-size: 21px;
      padding: 13px 18px;
      color: #ffffff;
      background-color: #00b0ec;
      font-weight: bold;

      .pp-close-model {
        display: inline-block;
        float: right;
        cursor: pointer;
      }
    }
    .pp-model-body {
      height: calc(100% - 100px);
      width: 100%;
    }
  }
  &.model-dialog {
    width: 95%;
  }
  &.model-dialog {
    width: 95%;
  }
}

.parent-signup-success {
  background-image: url(../shared/img/signup-success.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 60px;

  @include tablet-portrait {
    padding: 50px 20px 0;
    background-position: 30% 50%;
  }

  @include mobile {
    background-image: url(../shared/img/signup-success-mobile.png);
  }

  .logo-wrapper {
    margin-bottom: 36px;
    .logo {
      width: 153px;
    }
  }
  .title {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.58px;
    text-align: center;
    color: #030303;
    margin-bottom: 10px;
    font-family: "Open Sans", sans-serif;
  }
  .subtitle {
    opacity: 0.5;
    font-family: OpenSans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #030303;
    font-family: "Open Sans", sans-serif;

    @include mobile {
      padding: 0 26px;
    }
  }
  .success-wrapper {
    margin: 44px auto;
    width: 570px;
    padding: 32px 0 40px;
    border-radius: 12px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
    background-color: rgba(255, 255, 255, 0.88);

    &.error {
      padding-top: 65px;
    }

    @include mobile {
      width: 100%;
    }

    .message {
      img {
        width: 60px;
        height: 60px;
      }
      p {
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.68px;
        text-align: center;
        color: #030303;
        margin: 12px 0 44px;
        font-family: "Open Sans", sans-serif;

        @include mobile {
          margin-bottom: 47px;
        }
      }
    }
    .app-download {
      .btn-download {
        background-color: transparent;
        border: none;
        box-shadow: none;
        height: 48px;
        width: 145px;
        background-repeat: no-repeat;
        display: inline-block;

        @include mobile {
          display: block;
          margin: 0 auto;
        }

        &.google-play {
          background-image: url(../shared/img/logo/google-play.svg);
          width: 161.4px;
          margin-right: 15.6px;
          &.single-item {
            margin-right: auto;
          }
          @include mobile {
            background-image: url(../shared/img/logo/google-play-mobile.svg);
            width: 145px;
            margin: 10.9px auto 0;
          }
        }
        &.apple-store {
          background-image: url(../shared/img/logo/app-store.svg);
        }
      }
    }
  }
}
