.react-grid-Canvas {

  .react-grid-Row {

    .react-grid-Cell {

      .rdg-cell-action {
        float: none !important;

        .rdg-cell-action-menu {
          display: block;

          span {
            padding: 10px 15px;
          }
        }
      }

      .react-grid-Cell__value {

      }
    }
  }
}

.pagination__wrap {

  .pagination {
    float: right;
  }

  .perpage-wrapper {
    width: 200px;
    display: inline-block;
    height: 35px;

    select, select:focus {
      width: 80px;
      display: inline-block;
      border-radius: 0.25rem;
      border-color: #dde0e6;
      height: 35px;
      color: #3b284b;
      padding: 7px 10px 7px 7px;
      font-weight: 500;
      box-shadow: none;
    }

    p {
      display: inline-block;
      padding-left: 10px;
      margin: 0px 0 0 0;
      font-weight: 500;
    }
  }
}


.loyal-table {
  background-color: #fff;
  color: inherit;
  padding: 0;
  outline: 1px solid #e7eaec;
  clear: both;

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6f6f6;
  }

  .header-tr {
    background-color: #e0e2e0;
    border: none;
    border-right: 1px solid white;
    height: 44px;

    th {
      padding: 8px;
      border-right: 1px solid white;
      vertical-align: top;
      width: 100px;

      &.action {
        width: 100px;
      }

      p {
        float: left;
        color: #000000;
        font-size: 13px;
        font-weight: 600;
        margin: 0px;

        &.action {
          text-align: center;
          padding-left: 25px;
        }

        &span {
          cursor: pointer;
        }
      }

      .filter-icon {
        background: url(../shared/img/filter-icon.png);
        display: inline-block;
        height: 15px;
        width: 15px;
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-top: 5px;
        float: right;
        cursor: pointer;
      }

      .dropdown {
        display: inline-block;
        float: right;
      }

      .btn.btn-secondary {
        background-color: transparent;
        border-color: transparent;
        color: transparent;
        padding: 0px;
        margin: 0;
      }

      .btn.btn-secondary:not([disabled]):not(.disabled):active, .btn.btn-secondary:not([disabled]):not(.disabled).active {
        background-color: transparent;
        border-color: transparent;
        margin: 0;
      }

      .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:active, .btn.btn-secondary:active:focus {
        border-color: transparent;
        color: transparent;
        margin: 0;
      }

      .btn.btn-secondary:before {
        background-color: transparent;
      }
      .dropdown-menu {
        min-width: 140px;
        font-size: 14px;
        font-weight: 400;
        padding: 0px;
        border-radius: 0;
        top: 20px !important;
        left: 10px !important;
      }

      .dropdown-item {
        padding:10px 15px;
        color: #000;
        cursor: pointer;

        .form-check-input {
          margin-top: 2px;
        }
      }

      .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: #edf3f6;
      }


      .triangle-up {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #50514f;
        display: inline-block;
        float: right;
        margin-right: 5px;
        position: relative;
        top: 5px;
        cursor: pointer;
      }

      .triangle-down {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #50514f;
        display: inline-block;
        float: right;
        margin-right: 5px;
        position: relative;
        top: 5px;
        cursor: pointer;
      }

      .diamond-narrow {
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top: 6px solid #50514f;
        position: relative;
        top: 8px;
        float: right;
        margin-right: 5px;
        cursor: pointer;
        margin-left: 5px;

        &:after {
          content: '';
          position: absolute;
          left: -6px;
          top: -20px;
          width: 0;
          height: 0;
          border: 6px solid transparent;
          border-bottom: 6px solid #50514f;
          cursor: pointer;
        }
      }
    }

    .filter-select-box-wrapper {
      position: relative;
      /*z-index: 100;*/

      .search-state {
        position: relative;
        float: right;
        height: 40px;
        border-radius: 0;
        border: 1px solid #e0e2e0;
        background-color: #ffffff;
        display: inline-block;
        // remove this if need the search feature
        color: transparent;

        &:focus{
          outline: none;
          box-shadow: none;
          border-color: #ededee;
        }
        /*uncomment this if need the search feature
        color: #000000; */
        //@include placeholder-details(#96999c);
        &::-webkit-input-placeholder {
          color: #96999c;
        }
        @include font-size(14);
        font-weight: 400;
        line-height: 20px;

        background-image: url(../shared/img/arrow-down.png),;
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) center;
        padding-right: 35px;
      }

      .states-result {
        position: absolute;
        top: 20px;
        right: 0;
        /*min-width: 120%;*/
        overflow: hidden;

        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 4px 4px;
        background-color: #ffffff;
        border: 1px solid #edf3f6;
        background-color: $white;
        z-index: 1040;

        .blocks-wrapper {
          padding: 0;
          overflow: hidden;

          .states-block {
            margin: 0px 0;
            padding: 6px 15px;

            &:hover{
              background-color: #edf3f6;
            }

            label{
              margin-bottom: 0px;
            }

            .checkbox-input {
              float: left;

              &:checked {
                position: absolute;
                left: -9999px;
              }
              &:not(:checked) {
                position: absolute;
                left: -9999px;
              }
              &:checked + .checkbox-label {
                position: relative;
                padding: 0 10px 0 30px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 180px;

                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 2px;
                  width: 16px;
                  height: 16px;
                  border: 1px solid #20c83a;
                  border-radius: 2px;
                  background: #20c83a;
                }

                &:after {
                  content: '';
                  width: 10px;
                  height: 10px;
                  background: url(../shared/img/filter-states-tick.png),;
                  position: absolute;
                  top: 4px;
                  left: 3px;
                  border-radius: 2px;
                  transition: all 0.2s ease;
                  opacity: 1;
                  transform: scale(1);
                }
              }
              &:not(:checked) + .checkbox-label {
                position: relative;
                padding: 0 10px 0 30px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 180px;

                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 2px;
                  width: 16px;
                  height: 16px;
                  border: 1px solid #d8dadb;
                  border-radius:2px;
                  background: #ffffff;
                }

                &:after {
                  content: '';
                  width: 10px;
                  height: 10px;
                  background: #ffffff;
                  position: absolute;
                  top: 4px;
                  left: 3px;
                  border-radius: 2px;
                  transition: all 0.2s ease;
                  opacity: 0;
                  transform: scale(0);
                }
              }
            }

            .checkbox-label {
              color: #000000;
              @include font-size(14);
              font-weight: 400;
              line-height: 20px;
              text-transform: capitalize;

              &.select-all{
                text-transform: capitalize;
              }
            }

          }
        }

        .states-result-footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          overflow: hidden;
          box-shadow: inset 0 1px 0 rgba(150, 153, 156, 0.1);
          border-radius: 0 0 4px 4px;
          background-color: #f8f8f8;

          .btn-apply {
            margin: 10px 20px;
            float: right;
            padding: 12px 20px;
            color: #ffffff;
            @include font-size(13);
            font-weight: $font-weight-semi-bold;

            border-radius: 4px;
            background-color: #1b0f63;
            outline: none;
          }
        }
      }
    }
  }

  tbody {

    .body-tr {

      .custom-no-over-flow-td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 160px;
      }

      td {
        background-color: transparent;
        border: none;
        border-right: 1px solid white;
        color: #4d4d4d;
        font-size: 12px;
        font-weight: 400;
        padding: 8px;
        vertical-align: unset;

        .btn.btn-secondary {
          background-color: transparent;
          border-color: transparent;
          color: transparent;
          padding: 0px;
          margin: 0;
        }

        .btn.btn-secondary:not([disabled]):not(.disabled):active, .btn.btn-secondary:not([disabled]):not(.disabled).active {
          background-color: transparent;
          border-color: transparent;
          margin: 0;
        }

        .btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:active, .btn.btn-secondary:active:focus {
          border-color: transparent;
          color: transparent;
          margin: 0;
        }

        .btn.btn-secondary:before {
          background-color: transparent;
        }

        .six-layers {
          background: url(../shared/img/6-layers.png);
          display: block;
          height: 15px;
          width: 15px;
          background-repeat: no-repeat;
          margin-left: 35px;

          &.disabled{
            background: url(../shared/img/disabled-paw.png);
            cursor: initial;
            pointer-events: none;
          }
        }

        .dropdown-menu {
          min-width: 100px;
          font-size: 14px;
          font-weight: 400;
          padding: 0px;
          border-radius: 0;
        }

        .dropdown-item {
          padding: 0;
          color: #000;
          cursor: pointer;

          &:hover, &:focus, &:active{
            color: #16181b;
            text-decoration: none;
            background-color: #edf3f6;
          }

          a{
            padding: 9px 10px;
            display: block;
          }
        }

        .star-icon {
          fill: #2c2c7c;
          width: 15px;
          float: right;
        }
      }

    }
  }
}
