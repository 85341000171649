.topbar__button-icon {
  width: 20px;
}
.sidebar__link-icon {
  color: white;
}
.sidebar__profile {
  padding: 25px;
  padding-bottom: 0px;
  &--img {
    width: 72px !important;
    height: 72px !important;
    margin-top: 25px !important;
    margin-bottom: 20px !important;
  }

  &--name {
    width: 170px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--post {
    width: 170px;
    height: 23px;
    color: #a7a8a7;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
  }

  .logout_btn {
    border-radius: 5px;
    background-color: #1a2440 !important;
    margin-top: 30px;
    border-color: white !important;
    padding: 9px 25px;
    width: max-content;
    font-weight: 700;

    &:hover {
      background-color: #00b0ec !important;
      border-color: #00b0ec !important;
    }
  }
}

.sidebar__wrapper {
  &--chevron {
  }
  .sidebar__link {
    &.custom_sidebar_link {
      height: 44px;
      &:before {
        background: #72bb38;
      }

      .sidebar__link-icon {
        margin-top: 4px;
        font-size: 24px;
      }
    }
  }
  .sidebar__link-active {
    background: #72bb38;
  }
}

@media screen and (min-width: 576px) {
  .theme-light .sidebar.sidebar--collapse .sidebar__link:hover {
    background: #1a2440;
  }
}

.icons8-web-design {
  display: inline-block;
  width: 30px;
  height: 25px;
}

.icons8-broadcast-news {
  display: inline-block;
  width: 32px;
  height: 30.8px;
  margin-top: -5px;
}

.icons8-hospital-3 {
  display: inline-block;
  width: 30px;
  height: 28px;
}

.icons8-user-groups {
  display: inline-block;
  width: 30px;
  height: 28px;
  margin-left: -2px;
}

.icons8-doctors-bag {
  display: inline-block;
  width: 30px;
  height: 26px;
  margin-left: -2px;
}

.icons8-admin-settings-male {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: -1px;
}

.icons8-online-support {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: -2px;
}

.icons8-ptb {
  display: inline-block;
  width: 22px;
  height: 26px;
}

.icons8-calender {
  display: inline-block;
  width: 26px;
  height: 24px;
  margin-left: -2px;
}

.icons8-contact-details {
  display: inline-block;
  width: 30px;
  height: 24px;
  margin-left: -4px;
}

.icons8-paw {
  display: inline-block;
  width: 26px;
  height: 24px;
  margin-left: -2px;
}

.icons8-schedule {
  display: inline-block;
  width: 26px;
  height: 24px;
  margin-left: -2px;
}

.icons8-doctor {
  display: inline-block;
  width: 26px;
  height: 24px;
  margin-left: -2px;
}

.icons8-ask-question {
  display: inline-block;
  width: 28px;
  height: 26px;
  margin-left: -2px;
}
