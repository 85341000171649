body{
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
}
.heading-text {
    width: 100%;
    height: 23px;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
}